<script setup lang="ts">
  import type { QuotedPlanVm } from '~/models/quoting/QuotedPlan'
  import { AdditionalBenefitType, PlanBenefitType } from '~/generated/api-clients-generated'
  import { ProfileStore } from '~/stores/profile'

  const props = defineProps<{
    quote: QuotedPlanVm
    id: string
    hideHeader?: boolean
  }>()

  const { getId } = Id.use(props.id)
  const { show } = DialogController.use()

  const { additionalBenefitOptions } = await AdditionalBenefits.use('compact')
  const { benefitMap } = await BenefitDetailsMap.use()

  const profile = ProfileStore.use()

  const benefits = computed(() =>
    additionalBenefitOptions.value.map((abo) => ({
      ...abo,
      requested: profile.needsAssessment.additionalBenefits?.includes(abo.value),
      text: abo.value === AdditionalBenefitType.OtcMedications ? 'Over the Counter' : abo.text,
      details: benefitMap.value.find((b) => b.id === abo.value)?.getter(props.quote),
      covered: props.quote.details.selectedBenefits[PlanBenefitType[abo.planBenefitType]],
      sidebarId: getId.value(`${abo.value}-benefit-details-sidebar`)
    }))
  )
</script>

<template>
  <section class="flex w-full flex-col">
    <header class="content-section-header bg-gray-200" v-if="!hideHeader">
      <div>
        <h2>Additional Benefits</h2>
      </div>
    </header>
    <ul class="flex w-full flex-wrap gap-x-2 gap-y-4 py-4">
      <li
        @click="
          () => {
            if (benefit.covered) {
              show(benefit.sidebarId)
            }
          }
        "
        :class="[
          'relative flex items-center gap-x-1 rounded-xl border border-border px-3 py-2 font-semibold',
          { 'cursor-pointer shadow-[0px_4px_8px_rgba(111,_114,_133,_0.2)] ': benefit.covered },
          { 'select-none text-gray-500 line-through': !benefit.covered }
        ]"
        v-for="benefit in benefits"
        :key="benefit.value"
      >
        <HpIcon
          v-if="benefit.requested"
          name="heart-filled-circle"
          :class="['requested-benefit-marker', { 'not-covered': !benefit.covered }]"
        />
        <HpIcon
          :name="benefit.icon"
          :class="[{ 'text-primary': benefit.covered }, { 'text-gray-500': !benefit.covered }]"
        />
        {{ benefit.text }}
      </li>
    </ul>
    <QuoteCardBenefitDetailsSidebar
      v-for="ben in benefits"
      :icon="ben.icon"
      :label="ben.text"
      :id="ben.sidebarId"
      :details="ben.details"
    />
  </section>
</template>

<style scoped>
  .requested-benefit-marker {
    @apply absolute -left-2 -top-3;

    &.not-covered {
      :deep(rect) {
        @apply fill-gray-300;
      }

      :deep(path) {
        @apply fill-gray-600;
      }
    }
  }
</style>
